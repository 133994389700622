<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="imageModal"
    >   
        <CRow>
            <CCol col="12">
              <img style="width: 100%;" :src='item.image'>
            </CCol>
          </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'ProductModal',
  components: {
  },
  props: {
    
  },
  data () {
    return {
      imageModal: false,
      title: "Ver Imagen",
      color: "info",
      item: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    async showModal (item) {
      this.item = item;
      this.imageModal = true;
    },
    closeModal () {
      this.imageModal = false;
      this.item = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>