<template>
  <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-https"/> {{caption}}
              <div class="card-header-actions filters">
                
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :border="border"
              :small="small"
              :items="items"
              :fields="fields"
            >
              <template #image="{item}"><td class="w100 text-center"></td></template>
              <template #price="{item}"><td class="w100 text-center">
                <table style="width: 100%">
                  <tr>
                    <td colspan="2">
                      <span style="float: right;">{{item.id}}<br />{{item.size_label}}</span>
                      <span style="float: left;"><img style="width: 100px;" :src='item.thumb_image' @click="imageModal(item)"></span>
                      <br /><br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CInput
                        id="price"
                        name="price"
                        type="number"
                        :lazy="false"
                        v-model="item.price"
                      />
                    </td>
                    <td><CButton color="info" size="sm" @click="updatePrice(item)"><CIcon name="cil-save"/></CButton></td>
                  </tr>
                </table>
              </td></template>
            </CDataTable>
          </CCardBody>
        </CCard>

        <ImageModal ref="imageModal"></ImageModal>
      </CCol>
    </CRow>
</template>

<script>
import ImageModal from './ImageModal.vue'
import ws from '../../services/products';
import store from '../../store' 

export default {
  name: 'ProductPricesTable',
  components: { ImageModal },
  props: {
    items: Array,
    sizes: Array,
    tags: Array,
    colors: Array,
    materials: Array,
    types: Array,
    sizes_multiselect: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'price', label: 'Precio'},
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  data() {
    return {
      toasts: [],
    }
  },
  mounted: async function() {

  },
  methods: {
    async updatePrice(item) {
      let response = await ws.updatePrice(item.id, item.price);

      this.showToast(response.type, response.message);
      this.loaded();
    },
    imageModal (item) {
      this.$refs.imageModal.showModal(item);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
