<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ProductPricesTable
          :items="products"
          hover
          striped
          border
          small
          caption="Precios de Productos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ProductPricesTable from '../../components/products/ProductPricesTable.vue'

import products from '../../services/products';
import store from '../../store'

export default {
  name: 'ProductPrices',
  components: { ProductPricesTable },
  data: function () {
		return {
            products: []
        }
  },
  mounted: async function() {
    this.loading();

    let response = await products.get();

    if(response.type == "success"){
      this.products = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.products = await items;
      this.loaded();
    },
    updateModal (item) {
      this.loading();
    },
    async updateProcess(data) {
      let response = await products.update(data);

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
